.container {
    margin: 2rem auto;
    padding: 2rem;
    background-color: #F5F5F5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
  }
  
  .container h1 {
    color: #333333;
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .container p {
    color: #666666;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .container ul {
    list-style: disc;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
  
  .container li {
    color: #666666;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .container a {
    color: #0077FF;
    text-decoration: none;
  }
  
  .container a:hover {
    text-decoration: underline;
  }
  