/* about.css */

/* Container */
.about-container {
    margin: 2rem auto;
    padding: 2rem;
    background-color: #F5F5F5;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .about-container h2 {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }
  
  .about-container p {
    font-size: 2rem;
    margin-bottom: 3rem;
    line-height: 1.5;
  }
  
  .about-container .team {
    margin-top: 2rem;
  }
  
  @media (max-width: 768px) {
    .about-container {
      padding: 1.5rem;
    }
    
    .about-container h2 {
      font-size: 3rem;
    }
    
    .about-container p {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
  }
  
  @media (max-width: 576px) {
    .about-container {
      padding: 1rem;
    }
    
    .about-container h2 {
      font-size: 2.5rem;
    }
    
    .about-container p {
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }
  }
  /* Team Members */
  .team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .team-member {
    width: 250px;
    margin: 20px;
    text-align: center;
  }
  
  .team-member img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .team-member h3 {
    margin-top: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .team-member p {
    margin-top: 5px;
    font-size: 14px;
    color: #777;
  }
  
  /* Team Member Name */
  .team-member .member-name {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  /* Team Member Role */
  .team-member .member-role {
    margin-top: 5px;
    font-size: 14px;
    color: #777;
  }
  
  /* Team Member Description */
  .team-member .member-description {
    margin-top: 10px;
    font-size: 14px;
    color: #777;
  }
  
  /* Team Member Social Links */
  .team-member .social-links {
    margin-top: 15px;
  }
  
  .social-link {
    display: inline-block;
    margin-right: 10px;
    font-size: 20px;
    color: #777;
    transition: color 0.3s ease;
  }
  
  .social-link:hover {
    color: #007bff;
  }
  