.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    background: linear-gradient(to bottom, #cfd9df 0%, #e2ebf0 100%);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease-in-out;
  }
  
  .header:hover {
    background: linear-gradient(to bottom, #cfd9df 50%, #e2ebf0 100%);
  }
  
  .logo img {
    height: 60px;
  }
  
  nav ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  nav li {
    margin: 0 20px;
  }
  
  
  nav li a {
    font-size: 16px;
    color: #333;
    padding: 12px;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
    border-radius: 4px;
  }
  
  nav ul li a:hover {
    background-color: #707870;
  }
  
  .contact-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .contact-info p {
    margin: 0 10px 0 0;
    font-size: 14px;
    color: #666;
  }
  
  .contact-info button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .contact-info button:hover {
    background-color: #0056b3;
  }
  
  /* Media queries for responsiveness */
  @media screen and (max-width: 768px) {
    .header {
      flex-direction: column;
      height: auto;
      padding: 20px;
    }
  
    .logo img {
      margin-bottom: 20px;
    }
  
    nav ul {
      flex-direction: column;
    }
  
    nav li {
      margin: 10px 0;
    }
  }
  