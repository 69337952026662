.footer-container {
    background-color: #F5F5F5;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-right: 1rem;
  }
  
  .footer-button:hover {
    background-color: #0056b3;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-content p {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  
  .footer-content ul {
    list-style-type: none;
    margin-bottom: 1rem;
  }
  
  .footer-content li {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  
  .footer-bottom {
    text-align: center;
    font-size: 14px;
    margin-top: 1rem;
    color: #666;
  }
  