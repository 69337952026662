/* Testimonials Component */
.testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .testimonial-card {
    width: 250px;
    padding: 20px;
    margin: 20px;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  
  .testimonial-card:hover {
    transform: translateY(-5px);
  }
  
  .testimonial-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .testimonial-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .testimonial-icon {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .testimonial-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    border-radius: 3px;
    transition: all 0.3s ease-in-out;
  }
  
  .testimonial-button:hover {
    background-color: #0056b3;
  }
  
  
  .testimonial-card .testimonial-quote {
    font-size: 24px;
    color: #777777;
    margin-top: 20px;
  }
  
  .testimonial-card .testimonial-author {
    font-size: 14px;
    color: #777777;
    margin-top: 5px;
  }
  