.contact-us {
    display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
  
  .contact-us h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .contact-us form-group {
    display: grid;
    gap: 20px;
  }
  
  .contact-us input,
  .contact-us textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
  }
  
  .contact-us textarea {
    resize: none;
    height: 150px;
  }
  
  .contact-us button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #007bff;
        color: #fff;
        text-align: center;
        text-decoration: none;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;
      }
      
      .contact-us button:hover {
        background-color: #0056b3;
      }
      
  
  
  .contact-info {
    margin-top: 40px;
  }
  
  .contact-info h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .contact-info ul {
    list-style: none;
    padding: 0;
  }
  
  .contact-info li {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .contact-info i {
    margin-right: 10px;
    font-size: 18px;
    color: #007bff;
  }
  