.hero {
    background-color: #f7f7f7;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .hero h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.5rem;
    color: #777;
    text-align: center;
    max-width: 600px;
    margin-bottom: 2rem;
  }
  
  .hero button {
    background-color: #ff7043;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    border: none;
    border-radius: 0.5rem;
    padding: 1rem 2rem;
    transition: all 0.3s ease-in-out;
  }
  
  .hero button:hover {
    background-color: #ff8c66;
    cursor: pointer;
  }
  