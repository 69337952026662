.hero {
    margin: 3rem auto;
    padding: 3rem;
    background-color: #FDFDFD;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: center;
  }
  
  .hero h2 {
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
  
  .hero p {
    font-size: 2rem;
    line-height: 1.5;
    margin-bottom: 3rem;
  }
  
  .hero ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin-bottom: 2rem;
  }
  
  .hero ul li {
    margin: 10px;
    text-align: center;
    font-size: 1.5rem;
  }
  
  .hero ul li:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .hero {
      padding: 2rem;
    }
  
    .hero h2 {
      font-size: 3rem;
    }
  
    .hero p {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }
  
    .hero ul li {
      font-size: 1.25rem;
      margin: 5px;
    }
  }
  
  @media (max-width: 576px) {
    .hero {
      padding: 1.5rem;
    }
  
    .hero h2 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
    }
  
    .hero ul li {
      flex-basis: calc(50% - 20px);
      margin: 5px;
      font-size: 1rem;
    }
  }
  